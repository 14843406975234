<template>
  <div id="footer">
        <ul class="left">
          <p>주식회사 리브리오 <span class="bar">|</span> 대표 : 이상훈 <span class="bar">|</span> 대표번호 : 1811-0793 <span class="bar">|</span> 이메일 : <a href="mailto:3174mt@livrio.co.kr">3174mt@livrio.co.kr</a></p>
          <p>사업자등록번호 : 648-88-01401 <span class="bar">|</span> 주소 : 서울시 강남구 논현로28길 48, 5층</p>
<!--            <a href="javascript:void(0)" @click="nav('MemberPayment')" class="sns_instar">싼톡 인스타</a>-->
<!--            <a href="" class="sns_facebook">싼톡 페북</a>-->
        </ul>
        <ul class="right">
          <a href="https://www.livrio.co.kr" target="_blank">리브리오 소개</a><span class="bar">|</span>
            <a @click="showPolicy">이용약관</a><span class="bar">|</span>
            <a href="javascript:void(0);" @click="showPrivacy">개인정보처리방침</a>
            <span class="corp_name">© livrio Corp.</span>

        </ul>
        <div style="position:absolute; bottom:10px; right:10px"><img src="https://yeorcqadlpopsmgaoudu.supabase.co/storage/v1/object/public/certification/mark/8d1b2b27-86d5-4cf8-a8f7-da9074fb0e4d/d26b131f-a6c8-44f0-94c7-627576b28aba-2024-11-28T08:28:27.062Z" style="cursor: pointer; width: 40px; height: 40px;" onclick="(function(){window.open('https://cleanspam.or.kr/ci/157','_blank','width=800, height=1151, toolbar=no, menubar=no, scrollbars=no, resizable=no')})()"></div>
        <!--약관 띄우기 -->
        <!-- <ModalView v-if="isModalViewed" >
          <component v-bind:is="modalCompo" @close-modal="isModalViewed = false"></component>
        </ModalView> -->
    </div>


</template>

<script>
// import PolicyPopup from "@/views/login/PolicyPopup.vue";
// import PrivacyPopup from "@/views/login/PrivacyPopup.vue";
// import ModalView from "@/views/components/ModalView.vue";

export default {
  name: 'FooterPage',
  components: {
    // PolicyPopup,
    // PrivacyPopup,
    // ModalView,
  },
  data() {
    return {
      isModalViewed: false, // 모달 띄우기
      modalCompo: null,  // 모달 콤포넌트
    }
  },
  methods: {
    showPolicy: function () {
      // this.modalCompo = PolicyPopup;
      // this.isModalViewed = true;
      this.$router.push({
        name:'PolicyPage'
      })
    },
    showPrivacy: function () {
      // this.modalCompo = PrivacyPopup;
      // this.isModalViewed = true;
      this.$router.push({
        name:'PrivacyPage'
      })
    },
  }
}

</script>

<style scoped>

</style>
